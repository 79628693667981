
import { defineComponent } from 'vue';
import ResultCard from './ResultCard.vue';
import Repo from './Repo.vue';
import AgoDate from "./AgoDate.vue"
import User from "./User.vue";

export default defineComponent({
  props: {
    entry: {
      type: Object,
      required: true
    }
  },

  data() {
    return {};
  },

  components: {
    AgoDate,
    ResultCard,
    Repo,
    User
  },

  methods: {
    clickLabel(event:Event) {
      event.preventDefault();
      const target = event.target as HTMLAnchorElement;
      const label = target.text.trim();
      this.$emit('labelClicked', `label:${label}`);
    }
  },
});
