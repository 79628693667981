import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "apollo-example" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SearchForm = _resolveComponent("SearchForm")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SearchForm, {
      modelValue: _ctx.formData,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formData) = $event)),
      onDoSearch: _ctx.setFormData
    }, null, 8, ["modelValue", "onDoSearch"]),
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.resultsComponent), {
      formData: _ctx.formData,
      setQuery: _ctx.setQuery
    }, null, 8, ["formData", "setQuery"]))
  ]))
}