import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Issue = _resolveComponent("Issue")!
  const _component_Results = _resolveComponent("Results")!

  return (_openBlock(), _createBlock(_component_Results, {
    loading: _ctx.$apollo.loading,
    loadingNextPage: _ctx.loadingNextPage,
    results: _ctx.allResults.edges || [],
    error: _ctx.error,
    totalCount: _ctx.totalCount,
    searchType: _ctx.formData.issueType === 'pr' ? 'pull request' : 'issue',
    hasMore: _ctx.showShowMore,
    getMoreResults: _ctx.getMoreResults,
    setQuery: _ctx.setQuery
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList((_ctx.allResults.edges || []), (entry) => {
        return (_openBlock(), _createBlock(_component_Issue, {
          key: entry?.node?.id,
          "issue-data": entry?.node,
          onLabelClicked: _ctx.setQuery
        }, null, 8, ["issue-data", "onLabelClicked"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["loading", "loadingNextPage", "results", "error", "totalCount", "searchType", "hasMore", "getMoreResults", "setQuery"]))
}