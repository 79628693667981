import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_repo = _resolveComponent("repo")!
  const _component_AgoDate = _resolveComponent("AgoDate")!
  const _component_user = _resolveComponent("user")!
  const _component_result_card = _resolveComponent("result-card")!

  return (_openBlock(), _createBlock(_component_result_card, {
    title: _ctx.entry.commit ? _ctx.entry.commit.message : '',
    url: _ctx.entry.html_url,
    subTitle: _ctx.entry.sha.slice(0,8),
    subTitleTitle: _ctx.entry.sha
  }, {
    default: _withCtx(() => [
      _createVNode(_component_repo, _normalizeProps(_guardReactiveProps(_ctx.entry.repository)), null, 16),
      (_ctx.entry.author)
        ? (_openBlock(), _createBlock(_component_user, {
            key: 0,
            class: "commit__user",
            url: _ctx.entry.author.html_url,
            login: _ctx.entry.author.login,
            avatarUrl: _ctx.entry.author.avatar_url
          }, {
            default: _withCtx(() => [
              _createTextVNode(" commited "),
              _createVNode(_component_AgoDate, {
                date: _ctx.entry.commit.author.date
              }, null, 8, ["date"])
            ]),
            _: 1
          }, 8, ["url", "login", "avatarUrl"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["title", "url", "subTitle", "subTitleTitle"]))
}