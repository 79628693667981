import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_repo = _resolveComponent("repo")!
  const _component_result_card = _resolveComponent("result-card")!

  return (_openBlock(), _createBlock(_component_result_card, {
    title: _ctx.entry.path,
    url: _ctx.entry.html_url
  }, {
    default: _withCtx(() => [
      _createVNode(_component_repo, _normalizeProps(_guardReactiveProps(_ctx.entry.repository)), null, 16),
      _createElementVNode("code", null, _toDisplayString(_ctx.fragments), 1)
    ]),
    _: 1
  }, 8, ["title", "url"]))
}