
import { defineComponent } from 'vue';
import 'vue-loaders/dist/vue-loaders.css';
import SearchResults from "./components/SearchResults.vue";
import DarkModeToggle from './components/DarkModeToggle.vue';

export default defineComponent({
  name: "app",
  components: {
    DarkModeToggle,
    SearchResults
},
  created() {
    document.title = 'SilverStripe GitHub Issues';
  }
});
