
import { defineComponent, PropType } from 'vue';
import { Issue, User as UserType } from "@octokit/graphql-schema";
import ResultCard from './ResultCard.vue';
import Repo from './Repo.vue';
import AgoDate from "./AgoDate.vue"
import User from "./User.vue";

export default defineComponent({
  props: {
    issueData: {
      type: Object as PropType<Issue>,
      required: true
    }
  },

  components: {
    ResultCard,
    Repo,
    AgoDate,
    User
  },

  emits: {
    labelClicked(payload: string) {
      return payload;
    }
  },

  data() {
    return {};
  },

  computed: {
    /**
     * Helper to capitalize first letter of status and lowercase the rest.
     *
     * @return {String}
     */
    status() {
      const state = this.issueData.state;

      return state.charAt(0) + state.slice(1).toLowerCase();
    },

    /**
     * Helper to lowercase the state of the issue.
     *
     * @return {String}
     */
    statusLower() {
      return this.issueData.state.toLowerCase();
    },

    participants() {
      if (!this.issueData.participants || !this.issueData.participants.nodes || !this.issueData.author) {
        return null;
      }

      return (this.issueData.participants.nodes as UserType[]).filter(
        ({login}: {login: string}) => login !== this.issueData.author?.login
      );
    },

    /**
     * Helper to check if the issue has labels defined.
     *
     * @return {Boolean}
     */
    labelsDefined() {
      return (this.issueData.labels && this.issueData.labels.nodes) ? this.issueData.labels.nodes.length > 0 : 0;
    },
  },

  methods: {
    clickLabel(event:Event) {
      event.preventDefault();
      const target = event.target as HTMLAnchorElement;
      const label = target.text.trim();
      this.$emit('labelClicked', `label:${label}`);
    }
  }
});
