import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Results = _resolveComponent("Results")!

  return (_openBlock(), _createBlock(_component_Results, {
    loading: _ctx.loading,
    results: _ctx.allResults,
    error: _ctx.error,
    totalCount: _ctx.totalCount,
    searchType: _ctx.formData.issueType === 'commits' ? 'commit' : 'file',
    hasMore: _ctx.hasMore,
    getMoreResults: _ctx.nextPage,
    setQuery: _ctx.setQuery
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allResults, (entry) => {
        return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.resultComponent), {
          key: entry.sha,
          entry: entry
        }, null, 8, ["entry"]))
      }), 128))
    ]),
    _: 1
  }, 8, ["loading", "results", "error", "totalCount", "searchType", "hasMore", "getMoreResults", "setQuery"]))
}